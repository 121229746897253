<template>
    <div class="fhiry-nav-phone">
        <div class="icons" @click="show = true">
            <van-icon name="wap-nav" size="0.64rem" />
        </div>
        <popup
            v-model="show"
            position="top"
            :closeable="true"
        >
            <fhiry-nav-tree
                :data="data" :rank="1"
                @changeNav="show = false"
            ></fhiry-nav-tree>
        </popup>
    </div>
</template>

<script>
    // 组件
    import FhiryNavTree from './FhiryNavTree';
    import { Popup } from 'vant';

    export default {
        name: 'fhiry-nav-phone',
        props: {
            data: {
                type: Array,
                default () {
                    return []
                }
            }
        },
        data () {
            return {
                show: false
            }
        },
        components: {
            Popup,
            FhiryNavTree
        }
    }
</script>

<style lang="less" scoped>
    .fhiry-nav-phone {
        height: 100%;
        .icons {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
</style>