<template>
    <div class="fhiry-index">
        <header class="fhiry-header">
            <span class="left">
                <img :src="`${imgBaseURL}img/img/index/logo.png`" alt="">
            </span>
            <span class="right">
                <fhiry-nav-phone class="fhiry-nav-phone" :data="navs" />
                <fhiry-nav class="fhiry-nav-box" :data="navs" @changeNav="changeNav" @childChangeNav="changeNav" />
            </span>
        </header>
        <div class="fhiry-content">
            <router-view />
        </div>
        <footer class="fhiry-footer-box">
            <fhiry-footer :data="navs"/>
        </footer>
    </div>
</template>

<script>
    import FhiryNav from '@/components/FhiryNav';
    import FhiryNavPhone from '@/components/FhiryNavPhone';
    import FhiryFooter from '@/components/FhiryFooter';

    // vuex
    import { mapState, mapMutations } from 'vuex';

    // api
    import { getNav } from '@/api/request.js';

    export default {
        name: 'index',
        created () {
            this.getNavAjax();
        },
        data () {
            return {
                navs: []
            }
        },
        computed: {
            ...mapState([
                'imgBaseURL'
            ])
        },
        methods: {
            ...mapMutations([
                'setNav',
                'setNavs'
            ]),
            getNavAjax () {
                getNav().then(res => {
                    this.navs = res;
                    this.setNavs(this.navs);
                });
            },
            changeNav (nav) {
                this.setNav(nav);
            }
        },
        components: {
            FhiryNav,
            FhiryFooter,
            FhiryNavPhone
        }
    }
</script>

<style lang="less" scoped>
    .fhiry-index {
        height: 100vh;
        .fhiry-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20%;
            height: 80px;
            .left, .right {
                height: 100%;
                .fhiry-nav-phone {
                    display: none;
                }
            }
            .left {
                display: flex;
                align-items: center;
                width: 168px;
                img {
                    width: 100%;
                }
            }
        }
        .fhiry-content {
            min-height: calc(~'100% - 224px');
        }
    }

    @media screen and (min-width: 1440px) and (max-width: 1680px) {
        .fhiry-index {
            .fhiry-header {
                padding: 0 16%;
            }
        }
    }

    @media screen and (min-width: 1000px) and (max-width: 1439px) {
        .fhiry-index {
            .fhiry-header {
                padding: 0 12%;
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .fhiry-index {
            .fhiry-header {
                padding: 0 0.266667rem;
                height: 1.33333rem;
                .right {
                    .fhiry-nav-phone {
                        display: block;
                    }
                    .fhiry-nav-box {
                        display: none;
                    }
                }
                .left {
                    width: 3.2rem;
                }
            }
        }
    }
</style>