<template>
    <div class="fhiry-footer">
        <ul class="footer-links">
            <li v-for="(nav, index) in data" :key="index">{{ nav.text }}</li>
        </ul>
        <p class="copyright">版权所有 © 四川孚艾尔信息技术咨询有限公司</p>
        <div class="info">
            <p class="address">地址: 028-67871538</p>
            <p>地址: 中国-欧洲中心16层（成都天府大道南段与天府五街交叉口东150米）</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'fhiry-footer',
        props: {
            data: {
                type: Array,
                default () {
                    return []
                }
            }
        }
    }
</script>

<style lang="less">
    @import '~@/assets/less/common.less';

    .fhiry-footer {
        padding: 30px 0;
        background-color: @theme-color;
        color: #FFF;
        text-align: center;
        font-size: 12px;
        .footer-links {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
            li {
                position: relative;
                margin-right: 10px;
                color: #FFF;
                cursor: pointer;
                &:hover {
                    color: #ccc;
                }
                &:last-child {
                    margin: 0;
                    &:after {
                        width: 0;
                    }
                }
                &:after {
                    position: absolute;
                    right: -5px;
                    top: 3px;
                    bottom: 2px;
                    width: 1px;
                    background-color: #FFF;
                    content: '';
                }
            }
        }
        .copyright {
            margin-bottom: 6px;
        }
        .info {
            display: flex;
            justify-content: center;
            .address {
                margin-right: 40px;
            }
        }
    }

    @media screen and (max-width: 1023px) {
        .fhiry-footer {
            padding: 0.53333rem;
            font-size: 0.26666rem;
            .footer-links {
                margin-bottom: 0.53333rem;
            }
            .copyright {
                margin-bottom: 0.16rem;
            }
            .info {
                .address {
                    margin-bottom: 0.16rem;
                }
                flex-direction: column;
            }
        }
    }
</style>