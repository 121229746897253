<template>
    <div class="fhiry-nav">
        <ul class="fhiry-nav-list">
            <li class="fhiry-nav-item"
                v-for="(nav, index) in data"
                :key="index"
                :class="{'active-nav': activeNav.indexOf(nav.path) == 0}"
                @click.stop="changeNav(nav)"
                @mouseenter="mourseEnter(nav)"
            >
                {{ nav.text }}
            </li>
        </ul>
        <!-- 面板 -->
        <div class="panel"
             v-show="showPanel && nav != ''"
             @mouseleave="closePanel"
        >
            <div class="nav-title">
                {{ nav.text }}
            </div>
            <div class="nav-child"
                 v-for="(child, index) in nav.children"
                 :key="index"
            >
                <h1>{{ child.text }}</h1>
                <ul class="list"
                    v-if="child.children != void 0 && child.children.length != 0">
                    <li v-for="(cld, i) in child.children"
                        :key="i"
                        :class="{'active-sub-nav': cld.path == activeNav}"
                        @click="childChangeNav(nav, cld)"
                    >
                        {{ cld.text }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'fhiry-nav',
        created () {
            document.body.addEventListener('click', () => {
                this.closePanel();
            }, false);
        },
        beforeDestroy () {
            document.body.removeEventListener('click', () => {
                this.closePanel();
            }, false);
        },
        props: {
            // 导航数据
            data: {
                type: Array,
                default () {
                    return []
                }
            }
        },
        data () {
            return {
                showPanel: true,
                nav: ''
            }
        },
        computed: {
            activeNav () {
                return this.$route.path;
            }
        },
        methods: {
            changeNav (nav) {
                this.$router.push({
                    path: nav.path
                });
                this.$emit('changeNav', nav);
            },
            childChangeNav (nav, chil) {
                this.$router.push({
                    path: chil.path
                });
                this.$emit('childChangeNav', nav, chil);
            },
            mourseEnter (nav) {
                this.showPanel =
                    nav.children != void 0
                        && nav.children.length > 0 ? true : false;
                this.nav = this.showPanel ? nav : '';
            },
            closePanel () {
                this.showPanel = false;
            }
        }
    }
</script>

<style lang="less" scoped>
    @import '~@/assets/less/common.less';

    .fhiry-nav {
        height: 100%;
        .fhiry-nav-list {
            display: flex;
            align-items: center;
            height: 100%;
            .fhiry-nav-item {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 10px;
                height: 100%;
                font-size: 16px;
                cursor: pointer;
                &:after {
                    display: block;
                    position: absolute;
                    top: 0;
                    width: 0%;
                    height: 3px;
                    background-color: @theme-color;
                    transition: width 0.3s;
                    content: '';
                }
                &:hover, &.active-nav {
                    color: @theme-color;
                     &:after {
                         width: 100%;
                     }
                }
            }   
        }
        .panel {
            position: absolute;
            left: 0;
            right: 0;
            z-index: 999;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0 20px;
            height: 200px;
            background-color: rgb(255, 255, 255);
            box-shadow: 0 4px 4px #999;
            .nav-title, .nav-child {
                position: relative;
                padding-right: 20px;
                margin-right: 20px;
                height: 100%;
                &:after {
                    position: absolute;
                    right: 0;
                    top: 20px;
                    bottom: 20px;
                    width: 1px;
                    background-color: #ccc;
                    content: '';
                }
            }
            .nav-title {
                flex: 4;
                padding-top: 30px;
                font-size: 20px;
                text-align: right;
                color: @theme-color;
            }
            .nav-child {
                flex: 6;
                padding-top: 16px;
                h1 {
                    margin-bottom: 20px;
                    font-size: 14px;
                    color: #999;
                }
                .list {
                    display: flex;
                    flex-wrap: wrap;
                    li {
                        position: relative;
                        margin-bottom: 10px;
                        margin-right: 20px;
                        padding-bottom: 6px;
                        font-size: 16px;
                        &:after {
                            position: absolute;
                            left: 50%;
                            bottom: 0;
                            transform: translateX(-50%);
                            width: 0%;
                            height: 3px;
                            transition: width 0.3s;
                            background-color: @theme-color;
                            content: '';
                        }
                        &:hover, &.active-sub-nav {
                            color: @theme-color;
                            cursor: pointer;
                            &:after {
                                width: 100%;
                            }
                        }
                    }
                }
                &:last-child {
                    &:after {
                        width: 0;
                    }
                }
            }
        }
    }
</style>