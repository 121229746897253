<template>
    <ul class="fhiry-nav-tree">
        <li
            v-for="(nav, i) in data"
            :key="i"
            @click.stop="changeNav(nav, i)"
        >
            <div
                class="nav-info"
                :style="{ 'padding-left': `${(rank - 1) * 24 + 10}px` }"
                :class="{'active': nav.path == active}"
            >
                <span>{{ nav.text }}</span>
                <div class="rotate">
                    <van-icon
                        v-if="nav.children != void 0 && nav.children.length > 0"
                        name="arrow-down"
                        class="arrow-rotate"
                        :class="{'horizontal': show[i] == undefined ? false : !show[i]}"
                    />
                </div>
            </div>
            <fhiry-nav-tree
                v-if="nav.children != void 0 && nav.children.length > 0"
                v-show="show[i] == undefined ? false : show[i]"
                :data="nav.children"
                :rank="rank + 1"
                @changeNav="changeNavMethod"
            />
        </li>
    </ul>
</template>

<script>    
    export default {
        name: 'fhiry-nav-tree',
        props: {
            data: {
                type: Array,
                default () {
                    return [];
                }
            },
            rank: {
                type: Number,
                default: 1
            }
        },
        data () {
            return {
                show: []
            }
        },
        computed: {
            active () {
                return this.$route.path;
            }
        },
        methods: {
            changeNav (nav, i) {
                if (nav.children != void 0 && nav.children.length > 0) {
                    this.show[i] = this.show[i] == undefined ? true : !this.show[i];
                    this.$forceUpdate();
                    return;
                }
                this.$router.push({
                    path: nav.path
                });
                this.changeNavMethod();
            },
            changeNavMethod () {
                this.$emit('changeNav');
            }
        }
    }
</script>

<style lang="less" scoped>
    @import '~@/assets/less/common.less';

    .fhiry-nav-tree {
        font-size: 14px;
        li {
            .nav-info {
                position: relative;
                display: flex;
                justify-content: space-between;
                padding: 12px 10px;
                border-bottom: 1px solid #ccc;
                .rotate {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .arrow-rotate {
                        transition: all 0.3s;
                        &.horizontal {
                            transform: rotate(-90deg);
                            transform-origin: 6px 6px;
                        }
                    }
                }
                &.active {
                    &:after {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        width: 3px;
                        background-color: @theme-color;
                        content: '';
                    }
                }
            }
            ul {
                li {
                    .nav-info {
                        padding-left: 34px;
                    }
                }
            }
        }
    }
</style>